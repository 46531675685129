import React from 'react';
import { bool, func, node, string, arrayOf, oneOfType, object } from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';

import { IoIosClose } from 'react-icons/all';
import classNames from 'classnames';
import { DialogContent, DialogTitle } from '@material-ui/core';

import './full-page-modal.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogPaper: {
    minHeight: '95vh',
    maxHeight: '95vh',
  },
};

function FullPageModal({ children, open, onClose, showClose, classes, styleClasses, heading }) {
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      className={classNames('tw-w-full full-page-modal', styleClasses.join(' '))}
      id="full-screen-dialog"
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
    >
      {showClose && (
        <div className="tw-sticky tw-z-30 tw--mb-10 tw-right-0">
          <div className="tw-relative tw-flex tw-flex-row-reverse close-button">
            <IoIosClose
              className="tw-m-2 tw-w-8 tw-h-8 tw-opacity-50 hover:tw-opacity-100"
              id="popup-close-svg"
              role="button"
              onClick={() => onClose()}
            />
          </div>
        </div>
      )}
      {heading !== '' && <DialogTitle>{heading}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

FullPageModal.defaultProps = {
  open: false,
  showClose: true,
  onClose: () => {},
  classes: '',
  styleClasses: [],
  heading: '',
};

FullPageModal.propTypes = {
  children: node.isRequired,
  open: bool,
  onClose: func,
  showClose: bool,
  classes: oneOfType([string, object]),
  styleClasses: arrayOf(string),
  heading: oneOfType([node, string]),
};

const fullPageModal = withStyles(styles)(FullPageModal);
export { fullPageModal as FullPageModal };
