import React from 'react';

/**
 * 404 page for the app
 * @returns {*}
 * @constructor
 */
function NotFound(){
  return (<h4>404</h4>);
};

export { NotFound };
