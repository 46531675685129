import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Menu } from './Menu';
import AppContext from '../../AppContext';

import './menu.scss';

const MenuBar = () => {
  const { navigateToHome } = useContext(AppContext);
  const root = '/';

  return (
    <nav className="navbar sticky-top  navbar-expand-lg navbar-light" style={{ zIndex: 1024 }}>
      <div className="tw-flex tw-justify-center tw-items-center">
        <span className="navbar-brand tw-ml-2" onClick={navigateToHome} role="link">
          <Link to={root}>
            Home
          </Link>
        </span>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <Menu />
    </nav>
  );
};

export { MenuBar };
