import React, { useState } from 'react';
import { func, bool, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './loginStyle.scss';

const LoginForm = ({ action, onSubmitHandler, onProcessing, error, buttonCaption }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState({});

  const changeListener = e => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!password || !email) {
      return;
    }

    if (action === 'signup') {
      if (password.length < 8) {
        setFormError({
          msg: 'Passwords should be minimum of 8 characters in length',
        });
        return;
      }
    }

    onSubmitHandler({
      email,
      password,
      action,
    });
  };

  const buttonText = onProcessing ? 'Logging...' : buttonCaption;
  const errorMessage = _.get(error, 'msg') || _.get(formError, 'msg');

  return (
    <div className="card-body login-form">
      <img
        className="login-logo"
        alt=""
        src="https://images.squarespace-cdn.com/content/56f2595e8a65e2db95a7d983/1490853812955-FG3Z030NBYY8HCBKG3EU/HC_Logo_130px-02.png"
      />
      <form method="POST" onSubmit={onSubmit}>
        <div className="form-group error-msg">
          {errorMessage && (
            <span className="alert alert-danger">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email" className="col-form-label text-1-color">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="form-control"
            name="email"
            value={email}
            required
            placeholder="Eg: johndoe@gmail.com"
            onChange={changeListener}
          />
        </div>

        <div className="form-group password-container">
          <label htmlFor="password" className="col-form-label text-1-color">
            {action === 'signup' ? 'Create your password' : 'Password'}
          </label>
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="******"
            name="password"
            required
            value={password}
            onChange={changeListener}
          />
          {action === 'login' && <a href="/forgot-password">Forgotten password?</a>}
        </div>
        <div className="form-group">
          <button
            type="submit"
            disabled={onProcessing}
            className="btn btn-primary btn-block b-100-curved"
          >
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  onProcessing: false,
  action: 'login',
  error: {},
  buttonCaption: 'Login',
};

LoginForm.propTypes = {
  action: string,
  onSubmitHandler: func.isRequired,
  buttonCaption: string,
  onProcessing: bool,
  error: shape({
    msg: string,
  }),
};

export { LoginForm };
