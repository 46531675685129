import React from 'react';

function Stats() {
  return (
    <ul>
      <li>
        <h3>200+</h3>
        <p>Projects designed & delivered</p>
      </li>
      <li>
        <h3>10000+</h3>
        <p>Cost estimates provided</p>
      </li>
      <li>
        <h3>2000 Cr+</h3>
        <p>Value of estimated projects</p>
      </li>
      <li>
        <h3>50 Lakhs+</h3>
        <p>In sqft area of estimated projects</p>
      </li>
    </ul>
  );
}

Stats.propTypes = {};
Stats.defaultProps = {};

export { Stats };
