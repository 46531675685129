import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, shape, string, func, bool, instanceOf, oneOfType, number } from 'prop-types';
import { IoIosArrowDown, IoIosArrowUp, IoIosCheckmark } from 'react-icons/io';

import { isBlankOrEmptyString, isSubString } from 'utils';
import { useDetectOutsideClick } from 'hooks';

import './multi-select.scss';

function MultiSelect({
  items,
  placeholder,
  searchInput,
  selectedItems,
  onSelect,
  onRemove,
  single,
  maxSelection,
  highlightSelection,
  restrictedValues,
}) {
  const ref = useRef(null);
  const [options, updateOptions] = useState([]);
  const [shouldDisplayList, updateShouldDisplayList] = useState(false);
  const [searchTerm, updateSearchTerm] = useState('');
  useDetectOutsideClick(ref, () => updateShouldDisplayList(false));

  useEffect(() => {
    updateOptions(
      items.map(({ label, value }) => ({ label, value, selected: selectedItems.has(value) })),
    );
  }, [items]);

  useEffect(() => {
    const result = items
      .filter(({ label, value }) => {
        if (isBlankOrEmptyString(searchTerm)) {
          return true;
        }
        return isSubString(label, searchTerm) || isSubString(value, searchTerm);
      })
      .map(({ label, value }) => {
        return { label, value, selected: selectedItems.has(value) };
      })
      .sort((item1, item2) => item2.selected - item1.selected);
    updateOptions(result);
  }, [searchTerm, selectedItems.size]);

  useEffect(() => {
    if (maxSelection && maxSelection === selectedItems.size) {
      updateShouldDisplayList(false);
    }
  }, [selectedItems.size]);

  const handleOnSelect = value => {
    const index = options.findIndex(option => option.value === value);
    if (single && !options[index].selected === false) {
      return;
    }
    if (restrictedValues.has(options[index].value)) {
      return;
    }
    if (!options[index].selected === true && maxSelection && maxSelection === selectedItems.size) {
      // if maxSelection === 1, then unselected the previously selected value
      // choose the new one and close the dropdown overlay
      const selectedOptions = options.filter(item => item.selected);
      for (const selectedOption of selectedOptions) {
        const i = options.findIndex(optionItem => optionItem.value === selectedOption.value);
        options[i].selected = false;
        onRemove(options[i]);
      }

      options[index].selected = !options[index].selected;
      if (options[index].selected) {
        onSelect(options[index]);
      }
      updateShouldDisplayList(false);
      return;
    }
    options[index].selected = !options[index].selected;
    if (options[index].selected) {
      onSelect(options[index]);
    } else {
      onRemove(options[index]);
    }
    updateOptions([...options]);
    // Close automatically for single selection
    if (single) {
      updateShouldDisplayList(false);
    }
  };

  const renderSelectedOptions = () => {
    return options
      .filter(option => selectedItems.has(option.value))
      .map(option => option.label)
      .join(',');
  };
  return (
    <div className="select-wrapper tw-relative">
      <div onClick={() => updateShouldDisplayList(!shouldDisplayList)}>
        <div className="tw-flex tw-border tw-w-full tw-py-1 tw-px-1 tw-pt-2 tw-pb-2 tw-rounded tw-bg-white">
          {selectedItems.size > 0 ? (
            <span className="tw-w-3/4 tw-truncate tw-pl-1">{renderSelectedOptions()}</span>
          ) : (
            <span className="tw-w-3/4 text-2-color">{placeholder}</span>
          )}
          <span className="tw-flex tw-w-1/4 tw-flex-row-reverse tw-items-center tw-mr-2">
            {!shouldDisplayList && <IoIosArrowDown className="tw-cursor-pointer" />}
            {shouldDisplayList && <IoIosArrowUp className="tw-cursor-pointer" />}
          </span>
        </div>
      </div>

      {shouldDisplayList && (
        <div
          className="multi-select-options-div tw-absolute tw-mt-1 tw-w-full tw-rounded-md tw-bg-white tw-shadow-lg tw-z-50"
          ref={ref}
        >
          {searchInput && (
            <div className="tw-m-4">
              <input
                type="text"
                className="tw-w-full tw-border-2 tw-p-2 tw-outline-none"
                onChange={e => updateSearchTerm(e.target.value)}
                placeholder="Search"
              />
            </div>
          )}
          <ul
            tabIndex="-1"
            className="tw-h-auto tw-rounded-md tw-py-1 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm"
            style={{ maxHeight: '50vh' }}
          >
            {options.map(({ label, value, selected }) => (
              <li
                key={value}
                className={`tw-text-gray-900 tw-cursor-default tw-relative tw-py-2 tw-pl-3 tw-pr-9 ${
                  highlightSelection === true ? 'highlight-selection' : ''
                }`}
                onClick={() => handleOnSelect(value)}
              >
                <div className="tw-flex items-center tw-pl-4">
                  <span className="tw-block tw-truncate">{label}</span>
                </div>
                {selected && (
                  <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4">
                    <IoIosCheckmark className="cta-color tw-h-6 tw-w-6" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

MultiSelect.propTypes = {
  placeholder: string,
  items: arrayOf(shape({})),
  onSelect: func,
  onRemove: func,
  searchInput: bool,
  selectedItems: instanceOf(Set),
  single: bool,
  maxSelection: oneOfType([undefined, number]),
  highlightSelection: bool,
  restrictedValues: instanceOf(Set),
};

MultiSelect.defaultProps = {
  placeholder: 'select',
  items: [],
  onSelect: undefined,
  onRemove: undefined,
  searchInput: true,
  selectedItems: new Set(),
  single: false,
  maxSelection: undefined,
  highlightSelection: false,
  restrictedValues: new Set(),
};

export { MultiSelect };
