import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { number, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { GoogleLogin } from 'react-google-login';

import { API_BASE_URL, doPost } from 'utils';

import { LoginForm } from '../LoginForm';
import UserContext from '../../UserContext';
import Stats from '../Shared/Stats';
import { LoginLink } from '../LoginForm/LoginLink';
import { SignUpLink } from '../LoginForm/SignUpLink';

import { useNetwork } from '../../hooks';

import './login.scss';

/**
 * /login form
 * @param mode
 * @param next
 * @param location
 * @param boqId
 * @param formToDisplay
 * @returns {*}
 * @constructor
 */
const Login = ({ mode, next, location, boqId, formToDisplay }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { onLogin, isLoggedInUser } = useContext(UserContext);
  const { error, isLoading, data, doPostCall } = useNetwork();
  const [action, setAction] = useState('login');
  const routerState = { ...location };
  if (!routerState.state) {
    routerState.state = {};
  }
  const { from: previousLocation = next } = routerState.state;
  const ACTION_MAPPER = {
    signup: {
      buttonCaption: 'Sign Up',
      socialHeader: 'Or Sign up using',
      footer: (
        <LoginLink
          navigatedFrom={previousLocation}
          onClick={() => {
            if (mode === 'fullpage') {
              history.replace('/login', { from: previousLocation });
            } else {
              dispatch({
                type: 'SHOW_LOGIN_MODAL',
                payload: {
                  next,
                  boqId,
                  formToDisplay: 'login',
                },
              });
            }
          }}
        />
      ),
    },
    login: {
      buttonCaption: 'Login',
      socialHeader: 'Or Login using',
      footer: (
        <SignUpLink
          navigatedFrom={previousLocation}
          onClick={() => {
            if (mode === 'fullpage') {
              history.replace('/signup', { from: previousLocation, action: 'signup' });
            } else {
              dispatch({
                type: 'SHOW_LOGIN_MODAL',
                payload: {
                  next,
                  boqId,
                  formToDisplay: 'signup',
                },
              });
            }
          }}
        />
      ),
    },
  };
  const { footer, socialHeader, buttonCaption } = ACTION_MAPPER[action];
  const restrictedRedirectLocation = ['summary'];

  useEffect(() => {
    const navigateTo = restrictedRedirectLocation.some(item => item.includes(previousLocation))
      ? '/'
      : previousLocation;
    if (data) {
      onLogin(data);
      if (boqId) {
        doPost({ url: `/boqs/${boqId}/user` }).then(() => {
          window.location.href = navigateTo;
        });
      } else {
        window.location.href = navigateTo;
      }
    }
  }, [data]);

  useEffect(() => {
    if (history.location.state && history.location.state.action) {
      setAction(history.location.state.action);
    } else {
      setAction(formToDisplay);
    }
  }, [history.location.state, formToDisplay]);

  useEffect(() => {
   if(isLoggedInUser) {
     history.replace('/');
   }
  }, []);

  const handleSubmit = ({ email, password }) => {
    doPostCall({
      url: `${API_BASE_URL}/users/${action === 'login' ? 'login' : 'signup'}`,
      body: {
        email,
        password,
      },
    });
  };

  return (
    <div className="login-container">
      <div className="left">
        <div className="tw-relative tw-z-10">
          <div className="logo" style={{ textAlign: 'center' }}>
            <img
              src="/logo-white.svg"
              alt="logo-white.svg"
              style={{ width: '90px', marginBottom: '4em' }}
            />
          </div>
          <h1>Industry first real-time cost estimation & rate analysis</h1>
          <Stats />
        </div>
      </div>
      <div className="right">
        <div className="sm:tw-w-full md:tw-w-1/2">
          <LoginForm
            action={action}
            error={error}
            onSubmitHandler={handleSubmit}
            onProcessing={isLoading}
            buttonCaption={buttonCaption}
          />
        </div>
      </div>
    </div>
  );
};

Login.defaultProps = {
  boqId: undefined,
  mode: 'fullpage',
  next: '/',
  location: shape({
    state: {
      action: 'login',
      from: '/',
    },
  }),
  formToDisplay: 'login',
};

Login.propTypes = {
  boqId: number,
  mode: string,
  next: string,
  location: shape({}),
  formToDisplay: string,
};

export { Login };
