import { useEffect, useState } from "react";
import { Btn, MultiSelect } from "../Shared";
import { NetworkUtils } from "../../utils";
import { useDispatch } from "react-redux";
import { TagForm } from "./TagForm";
import { TaggedImages } from "./TaggedImages";

function TagCivil() {
  const dispatch = useDispatch();
  const [items, setitems] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const [existingImages, setExistingImages] = useState([]);

  const fetchCivilItems = async () => {
    const { data } = await NetworkUtils.doGet({ url: "/civil" });
    const temp = new Set();
    setitems(
      data
        .filter(({ id }) => {
          if (!temp.has(id)) {
            temp.add(id);
            return true;
          }
          return false;
        })
        .map(({ id, label }) => ({ value: id, label }))
    );
  };

  const fetchFinishesForCivilItem = async (civilItem) => {
    const { data } = await NetworkUtils.doGet({
      url: `/civil/${civilItem.value}`,
    });
    const { available_finish: availableFinishes = [] } = data;
    setFinishes(
      availableFinishes.map(({ id, label }) => ({ value: id, label }))
    );
  };

  useEffect(() => {
    fetchCivilItems();
  }, []);

  const fetchImages = async (form) => {
    try {
      let url = `reference-image?service=civil&item=${form.itemName}`;
      if (form.finish) {
        url += `&finish=${form.finish}`;
      }
      const { data } = await NetworkUtils.doGet({
        url,
      });
      setExistingImages(data);
    } catch (e) {
      dispatch({
        type: "DISPLAY_TOAST",
        payload: { type: "error", message: e.message },
      });
    }
  };
  return (
    <div className="tag-civil">
      <TagForm
        service="civil"
        items={items}
        OnFetchImagesBtnClick={fetchImages}
        finishes={finishes}
        onItemSelect={fetchFinishesForCivilItem}
      />
      <TaggedImages images={existingImages} />
    </div>
  );
}

TagCivil.propTypes = {};

TagCivil.defaultProps = {};

export { TagCivil };
