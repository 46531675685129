const initState = {
  undoFurnitureDeletion: {
    show: true,
    data: null,
  },
  addFurniture: {
    show: false,
    data: {},
  },
  bulkEdit: {
    show: false,
    data: {},
  },
  viewFurniture: {
    show: false,
    data: {},
  },
  loginModal: {
    show: false,
    data: {},
  },
  carousel: {
    show: false,
    data: {},
  },
  confirmationModal: {
    show: false,
    data: {},
  },
  viewCivil: {
    show: false,
    data: {},
  },
  addCivil: {
    show: false,
    data: {},
  },
  viewPainting: {
    show: false,
    data: {},
  },
  viewElectrical: {
    show: false,
    data: {},
  },
  viewFalseCeiling: {
    show: false,
    data: {},
  },
  viewSoftFurnishing: {
    show: false,
    data: {},
  },
  viewCostSummary: {
    show: false,
    data: {},
  },
  viewAssumption: {
    show: false,
    data: {},
  },
  toast: {
    show: false,
    data: {},
  },
  viewBoqInvitationForm: {
    show: false,
    data: {},
  },
  viewScheduleGraph: {
    show: false,
    data: {},
  },
  viewConsultWithUs: {
    show: false,
    data: {},
  },
  viewProductDemo: {
    show: false,
  },
  viewEditRatePopover: {
    data: {
      id: '',
      handleClose: () => {},
      anchorEl: null,
      formObj: {},
      currentRate: 0,
      onRateUpdate: () => {},
      onCancel: () => {},
    },
  },
  viewDesignFeeForm: {
    show: false,
    data: {
      formData: {
        boqId: null,
        value: 0,
        attribute: null,
      },
      callback: () => {},
    },
  },
  viewConfirmationPopup: {
    show: false,
    data: {
      onConfirm: () => {},
      onCancel: () => {},
    },
  },
};

function modalReducer(state = initState, action) {
  switch (action.type) {
    case 'SHOW_CAROUSAL':
      return {
        ...state,
        carousal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_CAROUSAL':
      return {
        ...state,
        carousal: {
          show: false,
        },
      };
    case 'SHOW_LOGIN_MODAL':
      return {
        ...state,
        loginModal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_LOGIN_MODAL':
      return {
        ...state,
        loginModal: {
          show: false,
        },
      };
    case 'SHOW_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: {
          show: false,
          data: {},
        },
      };
    case 'DISPLAY_TOAST':
      return {
        ...state,
        toast: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_TOAST':
      return {
        ...state,
        toast: {
          ...state.toast,
          show: false,
        },
      };
    case 'VIEW_CONFIRMATION_POPUP':
      return {
        ...state,
        viewConfirmationPopup: {
          show: true,
          data: { ...state.viewConfirmationPopup.data, ...action.payload },
        },
      };
    case 'HIDE_CONFIRMATION_POPUP':
      return {
        ...state,
        viewConfirmationPopup: {
          ...state.viewConfirmationPopup,
          show: false,
        },
      };
    default:
      return state;
  }
}

export { modalReducer };
