import { useEffect, useState } from "react";
import { NetworkUtils } from "../../utils";
import { useDispatch } from "react-redux";
import { TaggedImages } from "./TaggedImages";
import { TagForm } from "./TagForm";

function TagCarpentry() {
  const dispatch = useDispatch();
  const [items, setitems] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const [existingImages, setExistingImages] = useState([]);

  const fetchCarpentryItems = async () => {
    const { data } = await NetworkUtils.doGet({ url: "/catalog/all" });
    setitems(data.map(({ id, label }) => ({ value: id, label })));
  };

  const fetchFinishesForCarpentryItem = async (carpentryItem) => {
    const { data } = await NetworkUtils.doGet({
      url: `/catalog/${carpentryItem.value}/finishes`,
    });
    setFinishes(data.map(({ id, label }) => ({ value: id, label })));
  };

  useEffect(() => {
    fetchCarpentryItems();
  }, []);


  const fetchImages = async (form) => {
    try {
      const { data } = await NetworkUtils.doGet({
        url: `reference-image?service=carpentry&item=${form.itemName}&finish=${form.finish}`,
      });
      setExistingImages(data);
    } catch (e) {
      dispatch({
        type: "DISPLAY_TOAST",
        payload: { type: "error", message: e.message },
      });
    }
  };
  return (
    <div className="tag-carpentry ">
      <TagForm
        items={items}
        OnFetchImagesBtnClick={fetchImages}
        finishes={finishes}
        onItemSelect={fetchFinishesForCarpentryItem}
      />
      <TaggedImages images={existingImages} />
    </div>
  );
}

TagCarpentry.propTypes = {};

TagCarpentry.defaultProps = {};

export { TagCarpentry };
