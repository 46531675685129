class ApiError extends Error {
  name = 'ApiError';

  constructor(status, errors, message) {
    super(message);
    this.status = status;
    this.errors = errors;
    this.message = message;
  }
}

export { ApiError };
