import React from 'react';
import { arrayOf, shape, string } from "prop-types";

function TaggedImages({ images }){
    return (
        <div className="tw-w-full tw-flex tw-m-4 tw-gap-6">
            {images.map(({ url }) => (
                <div className="tw-border">
                    <img className="tw-object-contain tw-h-64 tw-w-96" src={url} alt="" />
                </div>
            ))}
        </div>
    );
}

TaggedImages.propTypes = {
    images: arrayOf(shape({
        url: string
    })),
};

TaggedImages.defaultProps = {
    images: [],
};

export { TaggedImages };