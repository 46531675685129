import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import withAuth from "components/Login/withAuth";
import { Login } from "components/Login";
import { ForgotPassword } from "components/ForgotPassword";
import { ResetPassword } from "components/ResetPassword";
import { NotFound } from "components/NotFound";
import { Loader } from "components/Loader";
import { ErrorView } from "components/ErrorView";
import { Home } from "components/Home";

import { UserProvider } from "./UserContext";

import "./App.scss";
import { TagCarpentry, TagCivil } from "./components/Tag";
import {Toast} from "./components/Shared/Toast";
import {MenuBar} from "./components/MenuBar";

function mapRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Login} />
      <Route exact path="/tag/carpentry" component={withAuth(TagCarpentry)} />
        <Route exact path="/tag/civil" component={withAuth(TagCivil)} />
      <Route exact path="/" component={withAuth(Home)} />
      {/* <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} /> */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function suspenseView() {
  return (
    <div>
      <Loader loading />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <ErrorView>
        <Router>
          <UserProvider>
            <Suspense fallback={suspenseView()}>
                <MenuBar />
              <div className="app-body">{mapRoutes()}</div>
              {/* modals that should use state data to render and work */}
                <Toast />
            </Suspense>
          </UserProvider>
        </Router>
      </ErrorView>
    </div>
  );
}

export default App;
