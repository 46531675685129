import React from 'react';
import { string, number, func } from 'prop-types';

function DimensionField({ label, feet, inches, onChange }) {
  const handleOnChange = (value, attribute) => {
    if (Number.isNaN(Number(value))) {
      return;
    }
    onChange(attribute, value);
  };
  return (
    <div className="dimension-fields tw-mb-4">
      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor={label}>
        {label}
      </label>
      <div className="tw-flex tw-w-full">
        <div className="tw-w-1/2">
          <input
            className="tw-appearance-none tw-border tw-rounded-l tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none"
            id="feet"
            type="text"
            value={feet}
            placeholder="Ft."
            onChange={event => handleOnChange(Number(event.target.value), 'feet')}
          />
        </div>
        <div className="tw-w-1/2">
          <input
            className="tw-appearance-none tw-border tw-rounded-r tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none"
            id="inches"
            type="text"
            value={inches}
            placeholder="In."
            onChange={event => handleOnChange(Number(event.target.value), 'inches')}
          />
        </div>
      </div>
    </div>
  );
}

DimensionField.propTypes = {
  label: string.isRequired,
  feet: number,
  inches: number,
  onChange: func.isRequired,
};

DimensionField.defaultProps = {
  feet: 0,
  inches: 0,
};

export { DimensionField };
