import React, { useContext } from "react";
import { Btn } from "components/Shared";

import UserContext from "../../UserContext";

import "./menu.scss";

const Menu = () => {
  const { isLoggedInUser, onLogout } = useContext(UserContext);
  return (
    <div className="tw-flex tw-justify-end tw-w-full tw-mr-4">
      <div>
        {isLoggedInUser && <Btn onClick={onLogout} text="Logout" />}
      </div>
    </div>
  );
};

export { Menu };
