import React from 'react';
import { Redirect } from 'react-router-dom';

import { isLoggedInUser } from 'utils';

export default function withAuth(ComponentToProtect) {
  return props => {
    if (!isLoggedInUser()) {
      return <Redirect to="/login" />;
    }
    return <ComponentToProtect {...props} />;
  };
}
