import _ from "lodash";
import axios from "axios";

import { getToken } from "./SessionUtils";
import { ApiError } from "./ErrorUtils";

// @TODO: Handle timeouts for the overall application, api is timing out
// sporadically when the db goes away!!!

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);

// For now, the application is using axios,
// but in future, it would be replaced with plain fetch or something similar
// if the bundle size is heavy. Hence use NetworkUtils.js to make network
// call instead of directly coding it to axios
const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const doGet = ({ url, headers = {}, params }) => {
  return api
    .get(url, {
      params,
      headers: {
        Authorization: getToken(),
        ...headers,
      },
    })
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      const responseStatus = _.get(err, "response.status", 400);
      const errors = _.get(err, "response.data.errors", {});
      const message = _.get(err, "response.data.msg", null);
      return Promise.reject(new ApiError(responseStatus, errors, message));
    });
};

export const doPost = ({ url, body, headers = {} }) => {
  return api({
    method: "POST",
    url,
    data: body,
    headers: {
      "Content-Type": "application/json",
      ...headers,
      Authorization: getToken(),
    },
  })
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      const error = _.get(err, "response.data", {});
      return Promise.reject(error);
    });
};

export const doPut = ({ url, body }) => {
  return api({
    method: "PUT",
    url,
    data: body,
    headers: { Authorization: getToken() },
  })
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      const error = _.get(err, "response.data.errors", {});
      return Promise.reject(error);
    });
};

export const doPatch = ({ url, body }) => {
  return api({
    method: "PATCH",
    url,
    data: body,
    headers: { Authorization: getToken() },
  })
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      const error = _.get(err, "response.data.errors", {});
      return Promise.reject(error);
    });
};

export const doDelete = ({ url, body = {} }) => {
  return api({
    method: "DELETE",
    headers: { Authorization: getToken() },
    url,
    data: body,
  })
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      const error = _.get(err, "response.data.errors", {});
      return Promise.reject(error);
    });
};

class NetworkUtils {
  static axiosInstance() {
    return axios.create({ baseURL: process.env.REACT_APP_API_URL });
  }
  static doGet({ url, headers = {}, params }) {
    return NetworkUtils.axiosInstance()
      .get(url, {
        params,
        headers: {
          Authorization: getToken(),
          ...headers,
        },
      })
      .then((response) => Promise.resolve(response.data))
      .catch((err) => {
        const responseStatus = _.get(err, "response.status", 400);
        const errors = _.get(err, "response.data.errors", {});
        const message = _.get(err, "response.data.msg", null) || _.get(err, "response.data.message", null);
        return Promise.reject(new ApiError(responseStatus, errors, message));
      });
  }

  static doPost({ url, body, headers = {} }) {
    return NetworkUtils.axiosInstance()({
      method: "POST",
      url,
      data: body,
      headers: {
        "Content-Type": "application/json",
        ...headers,
        Authorization: getToken(),
      },
    })
      .then((response) => Promise.resolve(response.data))
      .catch((err) => {
        const error = _.get(err, "response.data", {});
        return Promise.reject(error);
      });
  }
}

export { NetworkUtils };
