import React, { useState } from "react";
import { Btn, MultiSelect } from "../Shared";
import { NetworkUtils } from "../../utils";
import { useDispatch } from "react-redux";
import { string, arrayOf, shape, func } from "prop-types";

function TagForm({
  service,
  items,
  finishes,
  onItemSelect,
  OnFetchImagesBtnClick,
}) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    itemName: null,
    finish: null,
    file: null,
  });
  const [apiProcessing, setApiProcessing] = useState(false);
  const onFileChange = (event) => {
    setForm({ ...form, file: event.target.files[0] });
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.set("service", service);
    formData.set("item", form.itemName);
    if (form.finish) {
      formData.set("finish", form.finish);
    }
    formData.append("file", form.file, form.file.name);
    try {
      setApiProcessing(true);
      const { data: response } = await NetworkUtils.doPost({
        url: "reference-image",
        body: formData,
      });
      dispatch({
        type: "DISPLAY_TOAST",
        payload: { type: "success", message: response },
      });
      await OnFetchImagesBtnClick(form);
    } catch (e) {
      dispatch({
        type: "DISPLAY_TOAST",
        payload: { type: "error", message: e.message },
      });
    } finally {
      setApiProcessing(false);
    }
  };
  return (
    <div className="tw-flex tw-mt-4 tw-justify-center tw-items-center">
      <div className="tw-w-1/2 tw-border tw-p-8">
        <div className="tw-flex tw-items-center tw-gap-2">
          <span>Item:</span>
          <div className="tw-flex-1">
            <MultiSelect
              items={items}
              single
              searchInput
              maxSelection={1}
              selectedItems={new Set([form.itemName || ""])}
              onSelect={(item) => {
                setForm({ ...form, itemName: item.value, finish: null });
                onItemSelect(item);
              }}
              onRemove={() => {
                setForm({ ...form, itemName: "" });
              }}
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-gap-2 tw-mt-8">
          <span>Finish:</span>
          <div className="tw-flex-1">
            <MultiSelect
              items={finishes}
              maxSelection={1}
              single
              searchInput
              selectedItems={new Set([form.finish])}
              onSelect={(item) => {
                setForm({ ...form, finish: item.value });
              }}
              onRemove={() => {
                setForm({ ...form, finish: "" });
              }}
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-gap-2 tw-mt-8">
          <span className="tw-mr-4">File:</span>
          <input type="file" onChange={onFileChange} />
        </div>
        <div className="tw-flex tw-gap-3 tw-justify-end">
          <div>
            <Btn
              className="tw-mt-4"
              onClick={() => {
                OnFetchImagesBtnClick(form);
              }}
              text="View Images"
              disabled={
                form.itemName === null ||
                (form.finish === null && finishes.length > 0) ||
                apiProcessing === true
              }
            />
          </div>
          <div>
            <Btn
              className="tw-mt-4"
              onClick={async () => {
                setApiProcessing(true);
                await onSubmit();
              }}
              text={apiProcessing ? "Uploading...." : "Upload"}
              disabled={
                form.itemName === null ||
                (form.finish === null && finishes.length > 0) ||
                form.file === null ||
                apiProcessing === true
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TagForm.propTypes = {
  service: string,
  items: arrayOf(shape({})).isRequired,
  finishes: arrayOf(shape({})).isRequired,
  onItemSelect: func.isRequired,
  OnFetchImagesBtnClick: func.isRequired,
};

TagForm.defaultProps = {
  service: "carpentry",
};

export { TagForm };
