import { useHistory } from "react-router-dom";

function Home() {
  const history = useHistory();
  return (<div className="home tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-8 tw-flex-wrap">
      {['carpentry', 'civil', 'painting', 'electrical', 'false ceiling', 'soft furnishing'].map(service => (
          <div className="tw-border tw-rounded tw-p-4 tw-h-64 tw-w-64 tw-shadow tw-flex tw-justify-center tw-items-center tw-underline">
              <button type="button" onClick={() => {
                history.push(`tag/${service}`);
              }}><h4>{service}</h4></button>
          </div>
      ))}
  </div>);
}

Home.propTypes = {};

Home.defaultProps = {};

export { Home };
